.ant-layout .ant-layout-sider-trigger {
  border-top: 1px solid #eee;
  color: var(--primary-color);
}

.ant-menu-sub {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.ant-menu-item,
.ant-menu-submenu-title {
  padding-left: 18px !important;
}

.ant-menu-item-selected {
  color: white !important;
  background-color: var(--secondary-color) !important;
}

.ant-menu-submenu-popup {
  background-color: white !important;
}

.ant-tabs-tab-btn {
  display: flex;
  align-items: center;
  font-size: 13px;
}

.ant-tabs-tab-icon {
  display: flex;
}

.ant-menu-item-icon {
  font-size: 17px !important;
}

.ant-tabs-tab-btn,
.ant-tabs-dropdown-menu-item,
.ant-select-selection-placeholder {
  text-transform: capitalize;
}

.smallSelect .ant-select-selector {
  font-size: 13px;
}

.smallSelect .ant-select-item .ant-select-item-option-content {
  font-size: 13px !important;
  align-self: center;
}

.smallPicker input {
  font-size: 12px !important;
}
