html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

/* BACKGROUNDS */
.lightBg {
  background-color: #f2f2f2;
}
.lightBgTrans {
  background-color: rgba(0, 0, 0, 0.05);
}
.whiteBg {
  background-color: white;
}
.primaryBg {
  background-color: var(--primary-color);
}

/* RADIUS */
.rounded {
  border-radius: 7px;
}
.radius5 {
  border-radius: 5px;
}
.radius10 {
  border-radius: 10px;
}
.radius15 {
  border-radius: 15px;
}
.radius20 {
  border-radius: 20px;
}

/* FLEX */
.flex {
  display: flex;
}
.flexSpaceCenter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flexSpaceAround {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.flexNullCenter {
  display: flex;
  align-items: center;
}
.flexColumn {
  display: flex;
  flex-direction: column;
}
.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flexSpaceNull {
  display: flex;
  justify-content: space-between;
}
.flexEnd {
  display: flex;
  align-items: flex-end;
}
.flexNullEnd {
  display: flex;
  justify-content: flex-end;
}
.flexGrow {
  flex-grow: 1;
}
.flexWrap {
  display: flex;
  flex-wrap: wrap;
}

.xCenter {
  justify-content: center;
}

/* HELPERS */
.bold {
  font-weight: bold;
}
.semiBold {
  font-weight: 600;
}
.extraBold {
  font-weight: 900;
}
.textCenter {
  text-align: center;
}
.textRight {
  text-align: right;
}
.textNoWrap {
  text-wrap: nowrap;
}
.x100 {
  width: 100%;
}
.h100 {
  height: 100%;
}
.vh100 {
  height: 100vh;
}
.capitalize {
  text-transform: capitalize;
}
.animate {
  transition: 0.3s ease;
}
.pointer {
  cursor: pointer;
}
.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}
.scroll {
  overflow: scroll;
}
.borderBottom {
  border-bottom: 1px solid #eee;
}
.m0 {
  margin: 0;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.border {
  border: 1px solid #ddd;
}

/* GAPS */
.gap5 {
  gap: 5px;
}
.gap10 {
  gap: 10px;
}
.gap15 {
  gap: 15px;
}
.gap20 {
  gap: 20px;
}
.gap30 {
  gap: 30px;
}

/* TEXT COLOR */
.mainColor {
  color: var(--primary-color);
}
.primaryColor {
  color: var(--primary-color);
}
.secondaryColor {
  color: var(--secondary-color);
}
.blackColor {
  color: black;
}
.greyColor {
  color: grey;
}
.whiteColor {
  color: white;
}
.white70 {
  color: #ffffffb3;
}
.redColor {
  color: red;
}
.greenColor {
  color: green;
}

/* FONT SIZE */
.font10 {
  font-size: 10px;
}
.font11 {
  font-size: 11px;
}
.font12 {
  font-size: 12px;
}
.font13 {
  font-size: 13px;
}
.font14 {
  font-size: 14px;
}
.font15 {
  font-size: 15px;
}
.font18 {
  font-size: 18px;
}
.font20 {
  font-size: 20px;
}

.list-item-selected {
  border-left: 3px solid;
}
