/* MARGINS */
.mx10 {
  margin-block: 10px;
}

/* MARGIN BOTTOM */
.mb5 {
  margin-bottom: 5px;
}
.mb10 {
  margin-bottom: 10px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb30 {
  margin-bottom: 30px;
}

/* PADDING */
.p5 {
  padding: 5px;
}
.p10 {
  padding: 10px;
}
.p15 {
  padding: 15px;
}
.p20 {
  padding: 20px;
}
