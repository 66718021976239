:root {
  --primary-color: #150578;
  --secondary-color: #f97203;
}

input::placeholder,
textarea::placeholder {
  text-transform: capitalize;
}

body {
  background-color: #f2f2f2;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
