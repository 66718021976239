.scrollContainer {
  --sb-track-color: #ffffff00;
  --sb-thumb-color: #d2d2d2;
  --sb-size: 2.5px;
  overflow-x: hidden;
  overflow-y: hidden;
}

.scrollContainer::-webkit-scrollbar {
  width: var(--sb-size);
  background: transparent;
}

.scrollContainer::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 5px;
}

.scrollContainer::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 5px;
}

.scrollContainer:hover {
  overflow-y: auto;
}

@supports not selector(::-webkit-scrollbar) {
  .scrollContainer {
    scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
  }
}

.hideScroll::-webkit-scrollbar {
  display: none;
}

.hideScroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
